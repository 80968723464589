/*
* https://docs.mongodb.com/manual/reference/operator/query-comparison/
*/

export default {
  async asyncData ({ app, $content, route, error, store }) {
    let page = {}
    let website = {}

    try {
      let fragment = route.path

      if (fragment.startsWith('/')) fragment = fragment.substring(1)
      if (fragment.endsWith('/')) fragment = fragment.slice(0, -1)


      let path = `${ fragment }/index`

      if (!app.i18n.localeCodes.some(i => path.startsWith(`${i}/`))) {
        if (path.startsWith('/')) path = path.substring(1)
        path = `${app.i18n.defaultLocale}/${path}`
      }

      page = await $content(path).fetch()
      delete page.system

      // TODO
      // console.log('PAGE', page.multilingual)
      // await store.dispatch('i18n/setRouteParams', {
      //   de: { slug: page.multilingual.de.slug },
      //   en: { slug: page.multilingual.en.slug }
      // })

      website = await $content('website').fetch()

    } catch (e) {
      // console.log('ERROR CONTENT', e)
      error({ statusCode: 404, message: 'Not found' })
    }

    return {
      payload: {
        page: page,
        website: {
          title: website.title,
          live_url: website.live_url
        }
      },
    }
  },

  methods: {
    make1000wImage (src) {
      const extension = src.split('.').pop()
      const filePath = src.replace(`.${ extension }`, '')
      return `${ filePath }---1000.${ extension }`
    }
  },

  created () {
    this.$setWebsiteTitle(this.payload.website, this.payload.page)
    if (this.p) this.$store.commit('layout/setPageTemplate', this.p.template)
  },

  computed: {
    p () {
      return this.payload.page
    },

    w () {
      return this.payload.website
    },

    seoTitle () {
      return this.$store.state.seo.title
    },

    seoDescription () {
      return this.p.content && this.p.content.metaDescription ? this.p.content.metaDescription : ''
    },

    seoImage () {
      return this.p.content && this.p.content.metaImage && this.p.content.metaImage[0] ? `${ this.payload.website.live_url }uploads/${ this.make1000wImage(this.p.content.metaImage[0].filename) }` : ''
    },

    seoUrl () {
      return this.p.path ? `${ this.w.live_url }${ this.p.path.replace('/de/', '').replace('index', '').replace('/en/', 'en/')}` : ''
    }
  },

  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    const canonical = i18nHead.link.find(i => i.hid === 'i18n-can')
    canonical.href = canonical.href.endsWith('/') ? canonical.href : `${canonical.href}/`

    return {
      title: this.seoTitle,
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.seoTitle
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.seoDescription
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.seoUrl
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.seoImage
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          hid: 'description',
          name: 'description',
          content: this.seoDescription
        },
        ...i18nHead.meta
      ],
      link: [
        canonical
      ]
    }
  },

  mounted () {
    console.log(this.p)
    if (this.p.content && this.p.content.backgroundColor) {
      this.$store.commit('layout/setBackgroundColor', this.p.content.backgroundColor)
    } else {
      this.$store.commit('layout/setBackgroundColor', null)
    }

    const hasEmbed = this.p?.content?.layout?.[0]?.modules?.[0]?.content?.url
    this.$store.commit('layout/setIsTemplateFormVisible', this.p.template === 'form')
    this.$store.commit('layout/setIsTemplatePageVisible', !hasEmbed && (this.p.template === 'page' || this.p.template === 'article'))
  }
}
