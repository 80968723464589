import { render, staticRenderFns } from "./PageHome.vue?vue&type=template&id=885d0174"
import script from "./PageHome.vue?vue&type=script&lang=js"
export * from "./PageHome.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SnippetHero: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/snippets/SnippetHero.vue').default,ModulesIndex: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/modules/ModulesIndex.vue').default,SnippetFooter: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/snippets/SnippetFooter.vue').default,SnippetFrame: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/snippets/SnippetFrame.vue').default})
