import { theme } from '@/tailwind.config.js';

// https://nuxtjs.org/docs/2.x/components-glossary/pages-transition
// https://vuejs.org/v2/guide/transitions.html

export default {
  /*
  * Nuxt way of adding navigation guards
  * https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
  *
  * We have to set leaving states BEFORE transition kicks in,
  * because with transition the recomputation of watch and computed properties
  * are already removed
  */
  async validate ({ store, app, route }) {
    let _route = route.path
    if (!_route.endsWith('/')) _route = _route + '/'

    // console.log('VALIDATE', _route)

    store.commit('pageTransition/setEnter', _route)
    store.commit('pageTransition/setLeave', _route)
    await app.$nextDOMUpdate()
    return true
  },

  transition: {
    css: false,
    mode: 'in-out',
    type: 'transition',
    async enter (el, done) {
      setTimeout(async () => {
        this.$store.commit('pageTransition/setEnterDone')
      }, theme.transitionDuration['page'].replace('ms', ''))
      done()
    },
    async leave (el, done) {
      await this.$sleep(theme.transitionDuration['page'].replace('ms', ''))
      done()
    },
  },
}
